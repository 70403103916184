import React from 'react';
import Image from 'reusecore/src/elements/Image';
import { Link } from 'gatsby';
import DocumentationSection from '../../../../../containers/SaasModern/Documentation';
import vueminder_1 from '../../../../../../../common/src/assets/image/vueminder_1.png';

const Documentation = () => {
  const content = (
    <div>
      <p>
        PrintableCal can{' '}
        <Link to="/Documentation/Create-Calendar/Choose-a-Template">
          create printable calendars
        </Link>{' '}
        containing tasks, notes, and events defined in{' '}
        <a
          href="https://www.vueminder.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          VueMinder
        </a>{' '}
        - a full-featured calendar program developed by VueSoft, the makers of
        PrintableCal.{' '}
      </p>
      <p>
        PrintableCal handles customization and printing of your schedule, but if
        you're also looking for software that enables you to input your
        schedule, share your schedule with other people, and get reminders -
        VueMinder does all that and much more.
      </p>
      <p>
        To add VueMinder calendar data sources to PrintableCal, follow these
        steps:
      </p>
      <ol className="numbered">
        <li>
          Click the <strong>Add Calendar</strong> button on the Calendar Data
          Sources step and select the <strong>Local -&gt; VueMinder </strong>
          option. The <strong>Add Calendar Source - VueMinder </strong>window
          will appear. PrintableCal will try to detect VueMinder's data file
          location. If you're using VueMinder USB version, or if the default
          data folder location is incorrect, click the "..." button to select
          the correct data folder. The data folder should contain a file named
          CalendarInfo.bin.
          <br />
          <Image alt="" src={vueminder_1} style={{ width: 690 }} />
        </li>
        <li>
          Task, note, and event calendars will be listed. Each calendar will
          have a checkbox to the left of its name.
        </li>
        <li>
          Use the checkboxes to select the calendars you'd like to make
          available to PrintableCal.
        </li>
        <li>
          ​Click the <strong>OK </strong>button to add the selected VueMinder
          calendars to PrintableCal's list of available calendar sources.
        </li>
      </ol>
    </div>
  );

  return (
    <DocumentationSection
      url="https://www.printablecal.com/Documentation/Create-Calendar/Calendar-Data-Sources/VueMinder"
      commentsId="commentsplus_post_148_489"
      title="Print Calendars from VueMinder"
      description="PrintableCal can create printable calendars containing tasks, notes, and events defined in VueMinder."
      keywords="VueMinder, printable calendars, calendar templates, Excel calendar, Word calendar, best printable calendar"
      content={content}
    />
  );
};

export default Documentation;
